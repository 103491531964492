// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../../components/src/common";
export const configJSON = require("./config.js");
import { AudioContextApi } from "../../AudioContext/AudioContext.web";

interface BlockUser {
  message: string
}

export interface Props {
    selected: boolean;
    isVideo: boolean;
    unread: boolean;
    selectedComment: any;
    onUserCommentClick: () => void;
    onUserProfileClick: () => void;
    selectedComentReply: (comment: any) => void;
    index ?: any;
    commentEndRef ?: any;
    classes?:any;
    handleBlockUser: (id: number, isOpen: boolean) =>  void;
    deleteCommants:(id: number, isOpen: boolean) =>  void;
    handleReportModal: (isOpen: boolean) =>  void;
}

interface S {
    expanded: number;
    isBlockOpen: boolean;
    apiToken: string | null;
}
interface SS {
  id: any;
}

export default class CommentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiHandleCopyCountCallId: any;
  apiHandleVideoListCallId: any;
  apiUserBlockCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
        expanded: 0,
        isBlockOpen: false,
        apiToken: localStorage.getItem("token"),
    
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };


   handleClick = () => {
    this.props.onUserCommentClick()
}

 sendReply = (comment: any) =>{
    this.props.selectedComentReply(comment)
}


 commentID = (item: any) =>{
    return `comment_id_${item.id}`
}

 handleAccodionChange = (panel: any) => (
    event: any,
    newExpanded: any
  ) => {
    this.setState({expanded: newExpanded ? panel : false})
  };

   profileRedirection = () => {
    if(this.props.selectedComment?.attributes?.user_type == "creative"){
      HISTORY.push(`/creatives/beats?creative_id=${this.props.selectedComment?.attributes?.account_id}`)
    }else{
      HISTORY.push(`/listeners/activity?listener_id=${this.props.selectedComment?.attributes?.account_id}`)
    }
  } 

}
CommentsController.contextType = AudioContextApi;
// Customizable Area End