// Customizable Area Start
import React from "react";
import InboxCommentsController, { Props } from "./InboxCommentsController.web";
import "./Inbox.web.css";
import { Layout, Row } from "antd";
import { Hidden, Grid, Box, Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, FormControlLabel, RadioGroup, FormControl, Radio} from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import { nxzloader } from "../assets";
import CommentDMSelector from "./Common/CommentDMSelector.web";
import CommentList from "./Comments/CommentList.web";
import CommentListCP from "./Comments/CommentListCP.web";
import CommentsChatWeb from "./Comments/CommentsChatWeb.web";
import MediaProfileWeb from "./Comments/MediaProfileWeb.web";
import MediaProfileWebLP from "./Comments/MediaProfileWebLP.web";
import DMList from "../../../Sms/src/DMList.web";
import DMListCP from "../../../Sms/src/Creative/DMListCP.web";
import Default from "../../../Sms/src//Default.web";
import DirectMessage from "../../../Sms/src/DirectMessage.web";
import ParticularUser from "../../../Sms/src/ParticularUser/index.web";
import AsideLeft from "../../../../components/src/CustomChart/PopUps/AsideLeft.web";

// Customizable Area Start

// Customizable Area End
export class Inbox extends InboxCommentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderComments = () => {
    return (
      <>
        {this.state.selectedPage === "comment" ? (
          <>
            {this.state.userType === "listener" ? (
              <CommentList
                deleteCommants={(ids: number, isOpen: boolean) => this.deleteCommants(ids, isOpen)}
                navigation={this.props.navigation}
                id={this.props.id}
                setSelectedComment={(track: any) => {
                  this.setSelectedComment(track);
                }}
                setSelectedMedia={(track: any) => {
                  this.setSelectedMedia(track);
                }}
                setUnreadReplies={(replies: any) => {
                  this.setState({ unreadReplies: replies });
                }}
                setSelectedTrack={(track: any) => {}}
                handleBlockUser={(id: number, isOpen: boolean) =>  this.handleBlocksss(id, isOpen)}
              />
            ) : (
              <CommentListCP
                selectedComment={this.state.selectedComment}
                navigation={this.props.navigation}
                id={this.props.id}
                setSelectedComment={(track: any) => {
                  this.setSelectedComment(track);
                }}
                setSelectedMedia={(track: any) => {
                  this.setSelectedMedia(track);
                }}
                setUnreadComments={(comments: any) => {}}
                setSelectedTrack={(track: any) => {
                  this.setState({ selectedTrack: track });
                }}
                handleAddNewUser={(data: any) => this.handleAddNewUser(data)}
                setTrackIndex={(id: any) => {
                  this.setState({
                    selectedDMIndex: id,
                    selectedDMId: `${this.state.selectedTab}}`,
                  });
                }}
                selectedUserType={(selected: any) =>
                  this.setState({ selectedPage: selected })
                }
                isShowMsgModal={this.state.isShowMsgModal}
                closeNewMessage={() => this.setState({ isShowMsgModal: false })}
                openNewMessage={() => this.setState({ isShowMsgModal: true })}
                handleBlockUser={(id: number, isOpen: boolean) => this.handleBlocksss(id, isOpen)}
                deleteCommants={(id: number, isOpen: boolean) =>  this.deleteCommants(id, isOpen)}
                handleReportModal =
                {( isOpen: boolean) => this.handleReportModal(isOpen)}
              />
           )}
        </>
      ) : (
        <>
          {this.state.userType === "listener" ? (
               <DMList
               navigation={this.props.navigation}
               id={this.props.id}
               setSelectedComment={(track: any) => {
               this.setSelectedComment(track);
               }}
              setSelectedMedia={(track: any) => {
                this.setSelectedMedia(track);
              }}
              tracks={this.state.tracks}
              tempTracks={this.state.tempTracks}
                onClearNewAddedUser={this.onClearNewAddedUser1}
                handleUserId={this.handleUserId}
                viewUserDetail={this.viewUserDetail}
                searchText={(data: any) => this.setState({ searchText: data })}
                searchDMs={this.searchDMs}
                loading={this.state.loading}
                showCommentChatDialog={this.state.showCommentChatDialog}
                closeCommentChatDialog={this.closeCommentChatDialog}
                selectedMedia={this.state.selectedMedia}
                selectedComment={this.state.selectedComment}
                newUserAddedState={this.state.newUserAdded}
                sendComment={this.sendComment}
                selectedComentReply={this.selectedComentReply}
                selectedCommentForReply={this.state.selectedCommentForReply}
                showMediaDetailDialog={this.state.showMediaDetailDialog}
                closeProfileMedia={this.closeProfileMedia}
                selectedTrackMediaDetails={this.state.selectedTrackMediaDetails}
                openMessageLink={this.state.openMessageLink}
                openNewMessage={() => this.setState({ isShowMsgModal: true })}
                isShowMsgModal={this.state.isShowMsgModal}
                closeNewMessage={() => this.setState({ isShowMsgModal: false })}
                selectedUserType={this.state.selectedPage}
                handleAddNewUser={(data: any) => this.handleAddNewUser(data)}
                setTrackIndex={(id: any, dataId: any) => {
                  this.setState({
                    selectedDMIndex: id,
                    selectedDMId: `${this.state.selectedTab}/${dataId}`,
                  });
                }}
                selectedTrackIndex={this.state.selectedDMIndex}
                showSmallDMView={this.state.showSmallDMView}
                newUserAdded={() => this.setState({ newUserAdded: false })}
                otherUserID={this.state.otherUserId}
                showProfileDialog={this.state.showProfileDialog}
                particularUserDataRes={this.state.particularUserDataRes}
                updateParticulartUserData={this.getParticularUserData}
                selectedParticularList={this.state.selectedParticularList}
                generalListLoading={this.state.isGeneralLoading}
                followerListLoading={this.state.isFollowersLoading}
                setOtherUserId={(value: any) =>
                  this.setState({ otherUserId: value })
                }
                selectedTab={this.state.selectedTab}
                anchorEl={this.state.anchorEl}
                handleClose={this.handleClose}
                handleMenu={this.handleMenu}
                selectedTrackIndexId={(id: any) =>
                  this.setState({ selectedDMIndex: id })
                }
                handleBlockUser={(id: number, isOpen: boolean) => this.handleBlocksss(id, isOpen)}
              />
            ) : (
              <DMListCP
                navigation={this.props.navigation}
                id={this.props.id}
                setSelectedComment={(track: any) => {
                  this.setSelectedComment(track);
                }}
                setSelectedMedia={(track: any) => {
                  this.setSelectedMedia(track);
                }}
                setUnreadComments={(comments: any) => {
                  this.setState({ unreadComments: comments });
                }}
                setSelectedTrack={(track: any) => {
                  this.setState({ selectedTrack: track });
                }}
                selectedTab={this.state.selectedTab}
                handlePageChange={this.handlePageChange}
                tracks={this.state.tracks}
                onClearNewAddedUser={this.onClearNewAddedUser}
                tempTracks={this.state.tempTracks}
                handleUserId={this.handleUserId}
                viewUserDetail={this.viewUserDetail}
                searchText={(data: any) => this.setState({ searchText: data })}
                searchDMs={this.searchDMs}
                loading={this.state.loading}
                showCommentChatDialog={this.state.showCommentChatDialog}
                closeCommentChatDialog={this.closeCommentChatDialog}
                selectedMedia={this.state.selectedMedia}
                selectedComment={this.state.selectedComment}
                sendComment={this.sendComment}
                selectedComentReply={this.selectedComentReply}
                selectedCommentForReply={this.state.selectedCommentForReply}
                showMediaDetailDialog={this.state.showMediaDetailDialog}
                closeProfileMedia={this.closeProfileMedia}
                selectedTrackMediaDetails={this.state.selectedTrackMediaDetails}
                openMessageLink={this.state.openMessageLink}
                openNewMessage={() => this.setState({ isShowMsgModal: true })}
                isShowMsgModal={this.state.isShowMsgModal}
                closeNewMessage={() => this.setState({ isShowMsgModal: false })}
                selectedUserType={this.state.selectedPage}
                handleAddNewUser={(data: any) => this.handleAddNewUser(data)}
                setTrackIndex={(id: any, dataId: any) => {
                  this.setState({
                    selectedDMIndex: id,
                    selectedDMId: `${this.state.selectedTab}/${dataId}`,
                  });
                }}
                selectedTrackIndex={this.state.selectedDMIndex}
                showSmallDMView={this.state.showSmallDMView}
                newUserAdded={() => this.setState({ newUserAdded: false })}
                newUserAddedState={this.state.newUserAdded}
                otherUserID={this.state.otherUserId}
                showProfileDialog={this.state.showProfileDialog}
                particularUserDataRes={this.state.particularUserDataRes}
                updateParticulartUserData={this.getParticularUserData}
                selectedParticularList={this.state.selectedParticularList}
                generalListLoading={this.state.isGeneralLoading}
                followerListLoading={this.state.isFollowersLoading}
                setOtherUserId={(value: any) =>
                  this.setState({ otherUserId: value })
                }
                anchorEl={this.state.anchorEl}
                handleClose={this.handleClose}
                handleMenu={this.handleMenu}
                selectedTrackIndexId={(id: any) =>
                  this.setState({ selectedDMIndex: id })
                }
                handleBlockUser={(id: number, isOpen: boolean) => this.handleBlocksss(id, isOpen)}
              />
            )}
          </>
        )}
      </>
    );
  };

  renderUserDetailsComponent = () => {
    return (
      <>
        {this.state.selectedPage === "comment" ? (
          <>
            {this.state.userType === "listener" ? (
              <MediaProfileWebLP
                selectedTrack={this.state.selectedTrack}
                selectedTrackMediaDetails={this.state.selectedTrackMediaDetails}
                unreadReplies={this.state.unreadReplies}
                setFollowUser={(userId: any) => {
                  this.setFollowUser(userId);
                }}
                doUnFollow={(userID: any) => this.doUnFollow(userID)}
              />
            ) : (
              <MediaProfileWeb
                selectedMedia={this.state.selectedMedia}
                unreadComments={this.state.unreadComments}
                selectedTrack={this.state.selectedTrack}
                selectedTrackMediaDetails={this.state.selectedTrackMediaDetails}
                selectedTrackStatsDetails={this.state.selectedTrackStatsDetails}
                isShowMsgModal={this.state.isShowMsgModal}
                closeNewMessage={() => this.setState({ isShowMsgModal: false })}
                handleAddNewUser={(data: any) => this.handleAddNewUser(data)}
                setTrackIndex={(id: any, dataId: any) => {
                  this.setState({
                    selectedDMIndex: id,
                    selectedDMId: `${this.state.selectedTab}/${dataId}`,
                  });
                }}
                openNewMessage={() => this.setState({ isShowMsgModal: true })}
              />
            )}
          </>
        ) : (
          <ParticularUser
            navigation={this.props.navigation}
            id={this.props.id}
            otherUserID={this.state.otherUserId}
            particularUserDataRes={this.state.particularUserDataRes}
            updateParticulartUserData={this.getParticularUserData}
            selectedParticularList={this.state.selectedParticularList}
            setOtherUserId={(value: any) =>
              this.setState({ otherUserId: value })
            }
            setSelectedDMIndex={(value: any) =>
              this.setState({ selectedDMIndex: value })
            }
            setParticularUser={(value: any) =>
              this.setState({ particularUserDataRes: value })
            }
            newUserAddedState={this.state.newUserAdded}
            handleBlockUser={(id: number, isOpen: boolean) => this.handleBlocksss(id, isOpen)}
          />
        )}
      </>
    );
  };

  renderUserMessage = () => {
    return (
      <>
        {this.state.selectedPage === "comment" ? (
          <CommentsChatWeb
            selectedComment={this.state.selectedComment}
            selectedTrack={this.state.selectedTrack}
            setOnChangeNewComment={(value: any) => {
              this.setOnChangeNewComment(value);
            }}
            sendNewComment={(value: any) => this.sendNewComment(value)}
            newcomment={this.state.newComment}
            selectedComentReply={(comment: any) => {
              this.setState({ selectedCommentForReply: comment });
            }}
            selectedCommentForReply={this.state.selectedCommentForReply}
            closeCommentReply={() => {
              this.setState({ selectedCommentForReply: "" });
            }}
            deleteCommants={(id: number, isOpen: boolean) =>  this.deleteCommants(id, isOpen)}
            commentEndRef={this.commentEndRef}
            handleBlockUser={(id: number, isOpen: boolean) => this.handleBlocksss(id, isOpen)}
            handleReportModal={(isOpen: boolean)=>this.handleReportModal(isOpen)}
          />
        ) : (
          <>
            {this.state.otherUserId.length === 0 ? (
              <Default
                handleAddNewUser={(data: any) => this.handleAddNewUser(data)}
                generalListLoading={this.state.isGeneralLoading}
                followerListLoading={this.state.isFollowersLoading}
              />
            ) : (
              <DirectMessage
                navigation={this.props.navigation}
                id={this.props.id}
                otherUserID={this.state.otherUserId}
                newUserAdded={() => this.setState({ newUserAdded: false })}
                selectedTab={this.state.selectedTab}
                selectedTrackIndex={this.state.selectedDMId}
                particularUserDataRes={this.state.particularUserDataRes}
                selectedTrackIndexId={(id: any) =>
                  this.setState({ selectedDMIndex: id })
                }
              />
            )}
          </>
        )}
      </>
    );
  };


  renderBlockModal = () => {
    return (
      <>
        <Dialog open={this.state.isBlockOpen} onClose={this.closeModal} classes={{ paper: "dialog-paper" }}>
          <DialogTitle className="title">Block User?</DialogTitle>
          <DialogContent>
            <Typography className="content">
              This user will be hidden from you, and added to your blocked users list.
            </Typography>
          </DialogContent>
          <DialogActions className="actions">
            <Button className="red-button" onClick={() => this.getUserBlock()} data-test-id="blockbutton">
              Yes, Block
            </Button>
            <Button onClick={this.closeModal} className="cancel-button" data-test-id="cancleButton">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  renderDeleteModal = () => {
    return (
      <>
        <Dialog open={this.state.isDelete} onClose={this.closeModal} classes={{ paper: "dialog-paper" }}>
          <DialogTitle className="title">Delete Comment?</DialogTitle>
          <DialogContent>
            <Typography className="content">
            This comment will be permanently removed from NXZSound web and app platforms.
            </Typography>
          </DialogContent>
          <DialogActions className="actions">
            <Button className="red-button" onClick={() => this.deteleComment()} data-test-id="deleteButton">
              Yes, Delete
            </Button>
            <Button onClick={this.closeModal} className="cancel-button" data-test-id="cancleButton1">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  renderReportComments = () => {
    return (
  <>
   <Dialog
        open={this.state.isReportModalOpen}
        onClose={this.closeReportModal}
        maxWidth="xs"
        fullWidth
        classes={{ paper: "dialog-paper" }}
        PaperProps={{
          style: {
            borderRadius: "5px",
            backgroundColor: "#1a1a1a",
            color: "#fff",
            padding: "20px",
          },
        }}
      >
        <DialogTitle className="title">
          Report Comment
        </DialogTitle>
        <DialogContent>
          <Typography style={{ fontSize: "10px", marginBottom: "15px" }}>
            Seen or heard something on NZXSOUND that you want to report? Choose
            from the options below to report content that violates our platform
            rules, violates your intellectual property rights, or is otherwise
            illegal.
          </Typography>
          <Typography style={{ fontSize: "13px", fontWeight: "500" }}>
            Please select the best description of the content you're reporting
            below.
          </Typography>
          <FormControl component="fieldset" style={{ marginTop: "10px" }}>
            <RadioGroup
              // value={this.state.selectedReason}
              // onChange={this.handleReasonChange}
            >
              {this.state.reportCommentData.map((reason: any) => (
                <FormControlLabel
                  key={reason}
                  value={reason}
                  control={<Radio style={{ color: "#007bff",
                    //  '&.Mui-checked': { color: "#007bff" }
                     }} />}
                  label={reason}
                  style={{ marginBottom: "5px" }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{justifyContent:"flex-start"}}>
          <Button
            // onClick={this.handleSubmit}
            variant="contained"
            style={{
              backgroundColor: "#007bff",
              color: "#fff",
              textTransform: "none",
              borderRadius: "20px",
              padding: "8px 20px",
            }}
          >
            Send
          </Button>
          <Button
            onClick={this.closeReportModal}
            style={{
              color: "#bbb",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>

    )
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { showLoader, selectedPage } = this.state;
    const { isPlaylist } = this.context;
    let selectedCss: any;
    if (selectedPage === "comment") {
      selectedCss = "InboxMainContainer ";
    } else {
      selectedCss = "DMMainContainer";
    }
   
    return (
      <>
        <Layout className="MainLayoutContainer">
          <Hidden smDown>
            <AsideLeft />
          </Hidden>
          <Content className="MainContent">
            {/* <HomeHeader />  */}
            {this.renderBlockModal()}
            {this.renderDeleteModal()}
            {this.renderReportComments()}
            {showLoader ? (
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <img src={nxzloader} style={{ width: "10%" }} />
              </Row>
            ) : (
              <>
                <Grid
                  container
                  className={`${selectedCss} hide_in_mobile_commentdm`}
                  style={{
                    marginTop: "5px",
                    height: isPlaylist ? "85vh" : "100vh",
                  }}
                  spacing={4}
                >
                  <Grid
                    item
                    xs={4}
                    className="CommentContainerScroll"
                    style={{
                      padding: "0px 16px",
                      borderRight: "solid 1px #212121",
                      height: "100%",
                    }}
                  >
                    {selectedPage === "comment" ? (
                      <>
                        {this.state.userType === "listener" ? (
                          <CommentList
                            navigation={this.props.navigation}
                            id={this.props.id}
                            setSelectedComment={(track: any) => {
                              this.setSelectedComment(track);
                            }}
                            setSelectedMedia={(track: any) => {
                              this.setSelectedMedia(track);
                            }}
                            setUnreadReplies={(replies: any) => {
                              this.setState({ unreadReplies: replies });
                            }}
                            setSelectedTrack={(track: any) => {
                              this.setState({
                                selectedTrack: track,
                                selectedCommentForReply: "",
                              });
                            }}
                            handleBlockUser={(id: number, isOpen: boolean) =>  this.handleBlocksss(id, isOpen)}
                            deleteCommants={(id: number, isOpen: boolean) =>  this.deleteCommants(id, isOpen)}
                          />
                        ) : (
                          <CommentListCP
                          selectedComment={this.state.selectedComment}
                            navigation={this.props.navigation}
                            id={this.props.id}
                            setSelectedComment={(track: any) => {
                              this.setSelectedComment(track);
                            }}
                            setSelectedMedia={(track: any) => {
                              this.setSelectedMedia(track);
                            }}
                            setUnreadComments={(comments: any) => {
                              this.setState({ unreadComments: comments });
                            }}
                            setSelectedTrack={(track: any) => {
                              this.setState({
                                selectedTrack: track,
                                selectedCommentForReply: "",
                              });
                            }}
                            handleAddNewUser={(data: any) =>
                              this.handleAddNewUser(data)
                            }
                            setTrackIndex={(id: any) => {
                              this.setState({
                                selectedDMIndex: id,
                                selectedDMId: `${this.state.selectedTab}}`,
                              });
                            }}
                            selectedUserType={(selected: any) =>
                              this.setState({ selectedPage: selected })
                            }
                            isShowMsgModal={this.state.isShowMsgModal}
                            closeNewMessage={() =>
                              this.setState({ isShowMsgModal: false })
                            }
                            openNewMessage={() =>
                              this.setState({ isShowMsgModal: true })
                            }
                            deleteCommants={(id: number, isOpen: boolean) =>  this.deleteCommants(id, isOpen)}
                            handleBlockUser={(id: number, isOpen: boolean) =>  this.handleBlocksss(id, isOpen)}
                            handleReportModal = 
                            {(isOpen: boolean)=> this.handleReportModal(isOpen)}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {this.state.userType === "listener" ? (
                          <DMList
                            navigation={this.props.navigation}
                            id={this.props.id}
                            setSelectedComment={(track: any) => {
                              this.setSelectedComment(track);
                            }}
                            setSelectedMedia={(track: any) => {
                              this.setSelectedMedia(track);
                            }}
                            tracks={this.state.tracks}
                            tempTracks={this.state.tempTracks}
                            onClearNewAddedUser={()=> {
                              this.setState({
                                tempTracks:[],
                                    newUserAdded:false
                                })
                            }}
                            handleUserId={this.handleUserId}
                            viewUserDetail={this.viewUserDetail}
                            searchText={(data: any) =>
                              this.setState({ searchText: data })
                            }
                            searchDMs={this.searchDMs}
                            loading={this.state.loading}
                            showCommentChatDialog={
                              this.state.showCommentChatDialog
                            }
                            closeCommentChatDialog={this.closeCommentChatDialog}
                            selectedMedia={this.state.selectedMedia}
                            selectedComment={this.state.selectedComment}
                            sendComment={this.sendComment}
                            newUserAddedState={this.state.newUserAdded}
                            selectedComentReply={this.selectedComentReply}
                            selectedCommentForReply={
                              this.state.selectedCommentForReply
                            }
                            showMediaDetailDialog={
                              this.state.showMediaDetailDialog
                            }
                            closeProfileMedia={this.closeProfileMedia}
                            selectedTrackMediaDetails={
                              this.state.selectedTrackMediaDetails
                            }
                            openMessageLink={this.state.openMessageLink}
                            openNewMessage={() =>
                              this.setState({ isShowMsgModalMobile: true })
                            }
                            isShowMsgModal={this.state.isShowMsgModalMobile}
                            closeNewMessage={() => {
                              this.setState({ isShowMsgModalMobile: false });
                            }}
                            selectedUserType={this.state.selectedPage}
                            handleAddNewUser={(data: any) =>
                              this.handleAddNewUser(data)
                            }
                            setTrackIndex={(id: any, dataId: any) => {
                              this.setState({
                                selectedDMIndex: id,
                                selectedDMId: `${this.state.selectedTab}/${dataId}`,
                              });
                            }}
                            selectedTrackIndex={this.state.selectedDMIndex}
                            showSmallDMView={this.state.showSmallDMView}
                            newUserAdded={() =>
                              this.setState({ newUserAdded: false })
                            }
                            otherUserID={this.state.otherUserId}
                            showProfileDialog={this.state.showProfileDialog}
                            particularUserDataRes={
                              this.state.particularUserDataRes
                            }
                            updateParticulartUserData={
                              this.getParticularUserData
                            }
                            selectedParticularList={
                              this.state.selectedParticularList
                            }
                            generalListLoading={this.state.isGeneralLoading}
                            followerListLoading={this.state.isFollowersLoading}
                            setOtherUserId={(value: any) =>
                              this.setState({ otherUserId: value })
                            }
                            selectedTab={this.state.selectedTab}
                            selectedTrackIndexId={(id: any) =>
                              this.setState({ selectedDMIndex: id })
                            }
                            handleBlockUser={(id: number, isOpen: boolean) => this.handleBlocksss(id, isOpen)}
                          />
                        ) : (
                          <DMListCP
                            navigation={this.props.navigation}
                            id={this.props.id}
                            setSelectedComment={(track: any) => {
                              this.setSelectedComment(track);
                            }}
                            setSelectedMedia={(track: any) => {
                              this.setSelectedMedia(track);
                            }}
                            setUnreadComments={(comments: any) => {
                              this.setState({ unreadComments: comments });
                            }}
                            setSelectedTrack={(track: any) => {
                              this.setState({ selectedTrack: track });
                            }}
                            selectedTab={this.state.selectedTab}
                            handlePageChange={this.handlePageChange}
                            tracks={this.state.tracks}
                            tempTracks={this.state.tempTracks}
                            onClearNewAddedUser={this.onClearNewAddedUser}

                            handleUserId={this.handleUserId}
                            viewUserDetail={this.viewUserDetail}
                            searchText={(data: any) =>
                              this.setState({ searchText: data })
                            }
                            searchDMs={this.searchDMs}
                            loading={this.state.loading}
                            showCommentChatDialog={
                              this.state.showCommentChatDialog
                            }
                            closeCommentChatDialog={this.closeCommentChatDialog}
                            selectedMedia={this.state.selectedMedia}
                            selectedComment={this.state.selectedComment}
                            sendComment={this.sendComment}
                            selectedComentReply={this.selectedComentReply}
                            selectedCommentForReply={
                              this.state.selectedCommentForReply
                            }
                            showMediaDetailDialog={
                              this.state.showMediaDetailDialog
                            }
                            closeProfileMedia={this.closeProfileMedia}
                            selectedTrackMediaDetails={
                              this.state.selectedTrackMediaDetails
                            }
                            openMessageLink={this.state.openMessageLink}
                            openMessageLinkFalse={() =>
                              this.setState({ openMessageLink: false })
                            }
                            selectedUserType={selectedPage}
                            openNewMessage={() =>
                              this.setState({ isShowMsgModalMobile: true })
                            }
                            isShowMsgModal={this.state.isShowMsgModalMobile}
                            closeNewMessage={() =>
                              this.setState({ isShowMsgModalMobile: false })
                            }
                            handleAddNewUser={(data: any) =>
                              this.handleAddNewUser(data)
                            }
                            setTrackIndex={(id: any, dataId: any) => {
                              this.setState({
                                selectedDMIndex: id,
                                selectedDMId: `${this.state.selectedTab}/${dataId}`,
                              });
                            }}
                            selectedTrackIndex={this.state.selectedDMIndex}
                            showSmallDMView={this.state.showSmallDMView}
                            newUserAdded={() =>
                              this.setState({ newUserAdded: false })
                            }
                            newUserAddedState={this.state.newUserAdded}
                            otherUserID={this.state.otherUserId}
                            showProfileDialog={this.state.showProfileDialog}
                            particularUserDataRes={
                              this.state.particularUserDataRes
                            }
                            updateParticulartUserData={
                              this.getParticularUserData
                            }
                            selectedParticularList={
                              this.state.selectedParticularList
                            }
                            generalListLoading={this.state.isGeneralLoading}
                            followerListLoading={this.state.isFollowersLoading}
                            setOtherUserId={(value: any) =>
                              this.setState({ otherUserId: value })
                            }
                            selectedTrackIndexId={(id: any) =>
                              this.setState({ selectedDMIndex: id })
                            }
                            handleBlockUser={(id: number, isOpen: boolean) => this.handleBlocksss(id, isOpen)}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid
                    xs={4}
                    style={{
                      padding: "0px 10px",
                      borderRight: "solid 1px #212121",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CommentDMSelector
                      selectComment={this.switchPage}
                      selectDM={this.switchPage}
                      selectedPage={selectedPage}
                    />
                    {this.renderUserMessage()}
                  </Grid>
                  <Grid
                    xs={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      //  marginTop: '9px',
                      padding: "0px 10px",
                      height: "100%",
                    }}
                  >
                    {this.renderUserDetailsComponent()}
                  </Grid>
                  {/* </Grid> */}
                </Grid>

                <Grid
                  container
                  className={`${selectedCss} hide_in_desktop_commentdm`}
                  style={{ height: isPlaylist ? "85vh" : "100vh" }}
                >
                  {/* Comment/DM profiles */}
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: 50,
                      height: "100%",
                    }}
                  >
                    <Box className="CommentGrid" style={{ marginTop: "15px" }}>
                      <CommentDMSelector
                        selectComment={this.switchPage}
                        selectDM={this.switchPage}
                        selectedPage={selectedPage}
                      />
                      {this.renderComments()}
                    </Box>
                  </Grid>
                  <Grid md={3} item></Grid>
                  {/* Comment/DM profiles END */}

                  {/* Chat Box*/}
                  {/* Chat Box END*/}

                  {/* Profile Panel */}
                  {/* Profile Panel END */}
                </Grid>
              </>
            )}
          </Content>
        </Layout>
      </>
    );
    // Customizable Area End
  }
}
export default (Inbox);
// Customizable Area End
