Object.defineProperty(exports, "__esModule", {
  value: true
});
const urlConfig = require("../../../framework/src/config")
// Customizable Area Start
exports.dashboardGetUrl = "dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.getAPIMethod = "GET";
exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.posttAPIMethod = "POST";
exports.deleteAPIMethod = "DELETE";
exports.labelBodyText = "dashboard Body";
exports.playlistUrl = "bx_block_audiomusic/tracks_by_upload_type";
exports.alllistEndpoint = "/bx_block_audiomusic/web_user_tracks_by_upload_type";
exports.alllistTopSongs = "/bx_block_audiomusic/top_tracks";
exports.playListsEndPoint = '/bx_block_playlist/play_lists'
exports.profileNedpoint = '/bx_block_profile/profile'
exports.creativesById = '/bx_block_profile/user_profile'
exports.allVideos = '/bx_block_videos/top_videos'
exports.activityList = '/bx_block_audiomusic/user_history'
exports.allPlaylistEndpoint = '/bx_block_playlist/all_playlists'
exports.publicPlaylistEndpoint = '/bx_block_playlist'
exports.followUserUrl = '/account_block/follow_user?user_id='
exports.getdeleteAPIMethod = "GET";
exports.getlabelBodyText = "PUT";
exports.getplaylistUrl = "GET";
exports.getalllistEndpoint = "DELETE";
exports.getalllistTopSongs = "POST";
exports.getplayListsEndPoint = 'PUT';
exports.getprofileNedpoint = 'GET';
exports.getcreativesById = 'UPDATE';
exports.getallVideos = 'POST';
exports.getactivityList = 'DELETE';
exports.getallPlaylistEndpoint = 'POST';
exports.getdeleteAPIMethodReset = "GET";
exports.getlabelBodyTextResetPassword = "PUT";
exports.getplaylistUrlApi = "GET";
exports.getalllistEndReset = "DELETE";
exports.getalllistTopSongsPlaylist = "POST";
exports.getplayListsReset = 'PUT';
exports.getprofileEndDashboard = 'GET';
exports.getCreativeDashboard = 'UPDATE';
exports.getallVideosPlaylist = 'POST';
exports.getactivityListAdds = 'DELETE';
exports.getallPlaylistAds = 'POST';
exports.getpublicPlaylistEndpoint = 'PUT';
exports.getfollowUserUrl = 'DELETE';
exports.unfollowUserUrl = '/account_block/unfollow_user?user_id='
exports.followerLists = '/account_block/followers_list'
exports.endPointHandleFilter = "/bx_block_audiomusic/filter_tracks"
exports.creativeFilter = "/bx_block_audiomusic/creative_filter_tracks"
exports.updatePlaylist = "/bx_block_playlist/play_lists/"
exports.deletePlaylist = "/bx_block_playlist/play_lists/"
exports.getUserPlaylists = "/bx_block_playlist/play_lists"
exports.getVideoUserPlaylists = "/bx_block_playlist/video_play_list"
exports.addTrackToPlaylist = "/bx_block_playlist/add_track_to_playlist?"
exports.createPlaylist = "/bx_block_playlist/play_lists"
exports.getuserprofiledata = "/bx_block_profile/user_profile?"
exports.getReleaseVideo = "GET"
exports.getnewreleasevideo = "/bx_block_videos/user_profile_new_release_video?"
exports.getcreativeprofiletrack = "/account_block/accounts/creative_profile_track_percentage?"
exports.getallactivity = "/bx_block_activityfeed/activity_tab?"
exports.getfollowingdata = "/account_block/user_following?"
exports.getfollowerdata = "/account_block/user_follower?"
exports.getfollowerUserById = "/account_block/follow_user?"
exports.getunfollowUserById= "/account_block/unfollow_user?"
exports.getTop100Playlist= "/bx_block_playlist/top_playlist?"
exports.getNotificationData= "/bx_block_notifications/notifications/all_notification?"
exports.getcreativeResetProfile = "GET"
exports.getallResetPassword = "POST"
exports.getAllAccount = "PUT"
exports.getfollowerProfileReset = "GET"
exports.getfollowerUserByIdReset = "POST"
exports.getunfollowUserByIdResetProfile= "POST"
exports.getTopProfileReset= "POST"
exports.getResetPasswordOtp= "GET"
exports.gettop100UploadList= "/bx_block_audiomusic/creative_top_tracks?"
exports.gettopProfileVideos= "/bx_block_playlist/video_play_list"
exports.contentType = "application/json";
exports.blockUserEndPoint = "/account_block/block_users/block_account?blocked_account_id";
exports.deleteComment = "/bx_block_activityfeed/comments";
exports.blockUserListEndPoint  = "/account_block/block_users";
exports.unBlockUserEndPoint  = "/account_block/block_users/unblock_account?blocked_account_id";






exports.baseUrl = urlConfig.baseURL;

// Customizable Area End