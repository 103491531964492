// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { openNotification } from "../../../../../packages/components/src/Notification.web";
import { IBlock } from "../../../../framework/src/IBlock";
export const configJSON = require("../config");

interface ListBlock {
    data: Array<ListBlockObj>
}

export interface ListBlockObj {
    id: string;
    type: string;
    attributes: {
        id: number;
        email: string;
        first_name: string | null;
        last_name: string | null;
        user_name: string;
        followed_by_current_user: boolean;
        avatar: string;
        index: number;
        track_count: number;
        is_verified: boolean;
        arrow: boolean;
        role: string;
        total_play_count: number;
        total_comment_count: number;
        total_repost_count: number;
        last_twenty_four_hours_percentage: number;
        seven_days_percentage: number;
        blocked_user: boolean;
    }
}
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    apiToken: string | null;
    blockList: Array<ListBlockObj>;
    isModalOpen: boolean;
    userId: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UserBlockController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    blockUserApiCallId: string = "";
    unBlockApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            apiToken: localStorage.getItem("token"),
            blockList: [],
            isModalOpen: false,
            userId: NaN
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();

        this.getBlockUserList();
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          if (apiRequestCallId === this.blockUserApiCallId) {
              this.handleUserListSuccess(responseJson);
          } else if(apiRequestCallId === this.unBlockApiCallId){
            this.handleUnBlockUserSuccess(responseJson)
          }
          
        }
      }

    getBlockUserList = () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: this.state.apiToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.blockUserApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl +`${configJSON.blockUserListEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleUserListSuccess = (response: ListBlock) => {
        this.setState({ blockList : response.data });
    };

    handleUnBlockUser = () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: this.state.apiToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.unBlockApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl +`${configJSON.unBlockUserEndPoint}=${this.state.userId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleUnBlockUserSuccess = (responseJson: any) => {
        this.getBlockUserList()
        openNotification("Account unblocked successfully", "Success");
    };

    handleClose = () => {
        this.setState({ isModalOpen: false });
    };

    handleModal = (id: number) => {
        this.setState({ isModalOpen: true, userId: id });
    };


    // Customizable Area End
}
