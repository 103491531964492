// Customizable Area Start
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, Typography } from '@material-ui/core'
import { Layout } from 'antd'
import AsideLeft from '../../../../components/src/CustomChart/PopUps/AsideLeft.web'
import React from 'react'
import { Content } from 'antd/lib/layout/layout'
import HomeHeader from '../../../../components/src/CustomChart/PopUps/HomeHeader.web'
import "./UserBlock.css";
import UserBlockController, { ListBlockObj } from './UserBlockController.web'

// Customizable Area End

export default class UserBlockList extends UserBlockController {
    // Customizable Area Start
    renderBlockModal = () => {
        return (
          <>
            <Dialog open={this.state.isModalOpen} onClose={this.handleClose} classes={{ paper: "dialog-paper" }}>
              <DialogTitle className="title">Block User?</DialogTitle>
              <DialogContent>
                <Typography className="content">
                  This user will get unBlocked from your blocked users list.
                </Typography>
              </DialogContent>
              <DialogActions className="actions">
                <Button className="red-button" onClick={() => this.handleUnBlockUser()} data-test-id="blockbutton">
                  Yes, UnBlock
                </Button>
                <Button onClick={this.handleClose} className="cancel-button" data-test-id="cancleButton">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      }
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <div>
                <div className='downloadMain'>
                    <Layout className='downloadLayout'>
                        <Hidden smDown>
                            <AsideLeft />
                        </Hidden>
                        {this.renderBlockModal()}
                        <Content className='download_content'>
                            <HomeHeader />
                            <Box className='headerContainer'>
                                <p>Blocked Users</p>
                            </Box>

                            {this.state.blockList.length > 0 ?
                                this.state.blockList.map((item: ListBlockObj, index: number) => {
                                    return (
                                        <>
                                            <Box className='containerSection'>
                                                <Box className='userNameBox'>
                                                <p className='count'>{index + 1}.</p>
                                                <p className='username'>{item.attributes.user_name}</p>
                                                </Box>
                                                <button onClick={() => this.handleModal(item.attributes.id)} className='unBlockBtn' data-test-id="unBlockTestId">{"unBlock"}</button>
                                            </Box>
                                        </>
                                    )
                                })
                                :
                                <p className='no-downloads'>You don't have any blocked user </p>
                            }
                        </Content>
                    </Layout>
                </div>
            </div>
        )
        // Customizable Area End
    }
}
// Customizable Area Start
// Customizable Area End
